import axios from 'axios';

export default axios.create({
  // baseURL: `https://sparkling.kaltimprov.go.id/kehati-admin/api/`,
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// export default axios.create({
//   baseURL: `http://kehati.test/api/`,
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });
import React, {useState, useEffect} from 'react';
import HeroGlobal from '../../components/layouts/HeroGlobal';
import Loading2 from '../../components/layouts/loading/Loading2';
import '../../App.css'

//api
import BaseUrl from '../../store/Api'

function Ekosistem() {
  
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  
 
  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const dataRequest = {
          tmp_token: 'tysoftgroup'
        }
        let response = await BaseUrl.get(`deskripsi`,dataRequest)
        
        setData(response.data[1]) // 1 adalah data ekosistem
        setLoading(false)
        
      } catch (e) {
        setLoading(true)
        console.log(e.message)
      }
    }

    getData()
  }, [])

 

  return (
    <div>
      {
        loading ? <Loading2 className="mx-auto" /> :
        <div>
          <HeroGlobal
            idCss="heroGlobal"
            background="/assets/images/hero3.png"
            page="Kehati Kaltim"
            title={data.judul}
          />

          <div className="bg-white py-16 pb-32">
            <div className="container max-w-5xl mx-auto m-8 px-4 leading-relaxed text-lg">
              <div className='konten' dangerouslySetInnerHTML={{ __html: data.isi}} />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Ekosistem;
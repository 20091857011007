import React, {Suspense, lazy} from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import MyLoading from '../components/layouts/loading/MyLoading'
import Disclaimer from '../views/deskripsi/Disclaimer'
import Ekosistem from '../views/deskripsi/Ekosistem'
import GuestRoute from './GuestRoute'
import PrivateRoute from './PrivateRoute'


const Navbar = lazy(() => import('../components/layouts/Navbar'))
const ScrollToTop = lazy(() => import('./ScrollToTop'))
const Contact = lazy(() => import('../views/Contact'))
const NotFound = lazy(() => import('../views/errors/NotFound'))
const Home = lazy(() => import('../views/home'))
const Profil = lazy(() => import('../views/profil'))
const Berita = lazy(() => import('../views/berita'))
const BeritaDetail = lazy(() => import('../views/berita/BeritaDetail'))
const Katalog = lazy(() => import('../views/katalog'))
const Login = lazy(() => import('../views/auth/Login'))
const Daftar = lazy(() => import('../views/daftar'))
const Logout = lazy(() => import('../views/auth/Logout'))
const Akun = lazy(() => import('../views/member/akun'))
const Kehati = lazy(() => import('../views/member/kehati'))
const DokumentasiFlora = lazy(() => import('../views/member/dokumentasi/flora'))
const DokumentasiFauna = lazy(() => import('../views/member/dokumentasi/fauna'))
const Fauna = lazy(() => import('../views/database/fauna'))
const FaunaDetail = lazy(() => import('../views/database/fauna/Detail'))
const FaunaFilter = lazy(() => import('../views/database/fauna/Filter'))
const Flora = lazy(() => import('../views/database/flora'))
const FloraDetail = lazy(() => import('../views/database/flora/Detail'))
const FloraFilter = lazy(() => import('../views/database/flora/Filter'))
const Repo = lazy(() => import("../views/member/repo"));
const RepoUmum = lazy(() => import("../views/repo"));





function Router(props) {
  return (
    <div>
      <Suspense fallback={<MyLoading className="mx-auto" />}>
        <ScrollToTop />
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Navbar logo="black">
                <Home />
              </Navbar>
            </Route>
            <Route path="/profil/:slug">
              <Navbar>
                <Profil />
              </Navbar>
            </Route>
            <Route exact path="/database/fauna">
              <Navbar>
                <Fauna />
              </Navbar>
            </Route>
            <Route exact path="/database/fauna/filter">
              <Navbar>
                <FaunaFilter />
              </Navbar>
            </Route>
            <Route path="/database/fauna/:id">
              <Navbar>
                <FaunaDetail />
              </Navbar>
            </Route>
            <Route exact path="/database/flora">
              <Navbar>
                <Flora />
              </Navbar>
            </Route>
            <Route exact path="/database/flora/filter">
              <Navbar>
                <FloraFilter />
              </Navbar>
            </Route>
            <Route path="/database/flora/:id">
              <Navbar>
                <FloraDetail />
              </Navbar>
            </Route>
            <Route exact path="/database/ekosistem">
              <Navbar>
                <Ekosistem />
              </Navbar>
            </Route>
            <Route exact path="/disclaimer">
              <Navbar>
                <Disclaimer />
              </Navbar>
            </Route>
            <Route exact path="/berita">
              <Navbar>
                <Berita />
              </Navbar>
            </Route>
            <Route path="/berita/:slug">
              <Navbar>
                <BeritaDetail />
              </Navbar>
            </Route>
            <Route path="/katalog">
              <Navbar>
                <Katalog />
              </Navbar>
            </Route>
            <Route path="/repo">
              <Navbar>
                <RepoUmum />
              </Navbar>
            </Route>
            <Route path="/contact">
              <Navbar>
                <Contact />
              </Navbar>
            </Route>
            <GuestRoute path="/login">
              <Login />
            </GuestRoute>
            <GuestRoute path="/daftar">
              <Navbar>
                <Daftar />
              </Navbar>
            </GuestRoute>

            <PrivateRoute path="/logout">
              <Logout />
            </PrivateRoute>
            <PrivateRoute path="/akun">
              <Navbar>
                <Akun />
              </Navbar>
            </PrivateRoute>
            <PrivateRoute path="/member/kehati">
              <Navbar>
                <Kehati />
              </Navbar>
            </PrivateRoute>
            <PrivateRoute path="/dokumentasi/flora">
              <Navbar>
                <DokumentasiFlora />
              </Navbar>
            </PrivateRoute>
            <PrivateRoute path="/dokumentasi/fauna">
              <Navbar>
                <DokumentasiFauna />
              </Navbar>
            </PrivateRoute>
            <PrivateRoute path="/member/repo">
              <Navbar>
                <Repo />
              </Navbar>
            </PrivateRoute>
            <Route path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default Router;
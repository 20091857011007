import {selector, atom} from 'recoil'



// const getToken = atom({
//   key: 'token',
//   default: null
// })

// const getUser = atom({
//   key: 'user',
//   default: null
// })

// const serverName = atom({
//   key: 'link-server',
//   default: 'https://sparkling.kaltimprov.go.id/kehati-admin/'
// })

const serverName = atom({
  key: "link-server",
  default: process.env.REACT_APP_SERVER_NAME,
});

const getCheck = atom({
  key: 'check',
  default: false
})

const buttonTop = atom({
  key: 'button-top',
  default: false
})

const authenticated = selector({
  key: 'authenticated',
  get: async ({get}) => {
    let auth = {
      check: false,
      token: null
    }
    const cek = get(getCheck)
    if(cek){
      let {token, user} = await JSON.parse(localStorage.getItem('auth'))
      auth = {
        check: true,
        token,
        user
      }
      return auth
    }
    try {
      // const cek = await get(getCheck)
      let {token, user} = await JSON.parse(localStorage.getItem('auth'))
      auth = {
        check: true,
        token,
        user
      }
    } catch (e) {
      auth = {
        ...auth,
      }
    }
    return auth
  }
})

// const authenticated = selector({
//   key: 'authenticated',
//   get: async ({get}) => {
//     const check = get(getCheck);
//     const token = get(getToken)
//     const user = get(getUser)

//     try {
//       let {token, user} = await JSON.parse(localStorage.getItem('auth'))
//       const [, setCheck_] = useRecoilState(getCheck)
//       setCheck_(true)
//       token = token
//       user = user
//     } catch (e) {
//       // check = false
//       // console.log('error', e)
//     }

//     return {
//       check,
//       token,
//       user
//     }
//   }
// })

// 

export {authenticated, getCheck, buttonTop, serverName}
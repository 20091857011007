import React from 'react';
import {Route, Redirect} from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { authenticated } from '../store';

function GuestRoute({ children, ...rest }) {
  const auth = useRecoilValue(authenticated)
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.token ?  (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default GuestRoute;
import React from 'react'
import Router from './router'



// import './App.css'


function App(props) {
  
  return (
    <div>
      <Router/>      
    </div>
  );
}

export default App;